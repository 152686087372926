<div aria-label="Logo">
  <a
    routerLink="/{{ language }}"
    aria-label="Revenir à la page d'accueil"
    (click)="onSidenavClose()"
  >
    <img
      role="img"
      alt="logo my astuce"
      aria-label="logo-my-astuce Revenir à la page d'accueil"
      id="app-logo"
      src="assets/site/new-logo-myastuce.png"
      alt="Logo My astuce"
      style="height: 36px; width: 100px"
    />
  </a>
</div>

<nav
  fxHide.sm
  fxHide.xs
  aria-hidden="false"
  aria-label="Navigation"
  id="block-nav-main-menu"
>
  <ul fxLayout class="navigation-items">
    <li>
      <button
        type="button"
        role="navigation"
        mat-button
        aria-label="se déplacer"
        aria-expanded="false"
        [matMenuTriggerFor]="panelSeDeplacer.menu"
        (menuOpened)="menuOpened('panelSeDeplacer')"
        (menuClosed)="menuClosed('panelSeDeplacer')"
        aria-label="Se déplacer"
        class="nav-link px-2 link-dark"
        [ngClass]="{
          cssCurrentPanelOpened: currentMenuPanel === 'panelSeDeplacer'
        }"
      >
        {{ "menu.seDeplacer" | translate }}
      </button>
      <app-menu-panel
        #panelSeDeplacer
        [items]="items['SE-DEPLACER']"
      ></app-menu-panel>
    </li>
    <li>
      <button
        type="button"
        mat-button
        role="navigation"
        aria-label="s'informer"
        aria-expanded="false"
        class="nav-link px-2 link-dark"
        (menuOpened)="menuOpened('panelSInformer')"
        (menuClosed)="menuClosed('panelSInformer')"
        [ngClass]="{
          cssCurrentPanelOpened: currentMenuPanel === 'panelSInformer'
        }"
        [matMenuTriggerFor]="panelSInformer.menu"
      >
        {{ "menu.sInformer" | translate }}
      </button>
      <app-menu-panel
        #panelSInformer
        [items]="items['S-INFORMER']"
      ></app-menu-panel>
    </li>
    <li>
      <button
        type="button"
        mat-button
        role="navigation"
        aria-label="acheter"
        aria-expanded="false"
        (menuOpened)="menuOpened('panelAcheter')"
        (menuClosed)="menuClosed('panelAcheter')"
        [ngClass]="{
          cssCurrentPanelOpened: currentMenuPanel === 'panelAcheter'
        }"
        [matMenuTriggerFor]="panelAcheter.menu"
        class="nav-link px-2 link-dark"
      >
        {{ "menu.acheter" | translate }}
      </button>
      <app-menu-panel #panelAcheter [items]="items['ACHETER']"></app-menu-panel>
    </li>
  </ul>
</nav>

<app-search-bar fxHide.sm fxHide.xs></app-search-bar>

<div style="display: flex; align-items: center" fxHide.sm fxHide.xs>
  <button
    type="button"
    role="button"
    aria-label="Changer la langue du site. bouton réduit"
    aria-expanded="false"
    mat-button
    (menuOpened)="menuOpened('panelLangue')"
    (menuClosed)="menuClosed('panelLangue')"
    [ngClass]="{
      cssCurrentPanelOpened: currentMenuPanel === 'panelLangue'
    }"
    [matMenuTriggerFor]="panelLangue"
    class="nav-link language-select"
  >
    <img
      *ngIf="language === 'fr'"
      class="img-langue"
      src="../../../assets/site/fr.svg"
      alt="drapeau français"
    />
    <img
      *ngIf="language === 'en'"
      class="img-langue"
      src="../../../assets/site/en.svg"
      alt="drapeau anglais"
    />
    <span class="language-text">
      {{ language | uppercase }}
    </span>
  </button>

  <mat-menu #panelLangue="matMenu">
    <button
      type="button"
      aria-label="Cliquez pour changer la langue du site en Français"
      mat-menu-item
      (click)="switchLocale('fr')"
    >
      <img
        alt="drapeau français"
        class="img-langue"
        src="../../../assets/site/fr.svg"
        alt="fr"
      />
      <span translate>menu.francais</span>
    </button>
    <!--TODO : Décommenter ce code pour activer la langue English-->
    <!-- <button
      type="button"
      aria-label="Cliquez pour changer la langue du site en Anglais"
      mat-menu-item
      (click)="switchLocale('en')"
    >
      <img
        alt="drapeau anglais"
        class="img-langue"
        src="../../../assets/site/en.svg"
        alt="en"
      />
      <span translate>menu.anglais</span>
    </button> -->
  </mat-menu>

  <button
    aria-label="Affichage"
    type="button"
    aria-haspopup="true"
    aria-expanded="false"
    class="nav-link px-2 link-dark no-arrow button dropdown-button display-buton"
    id="dropdownMenuButton"
    [matMenuTriggerFor]="display"
  >
    <div class="display">
      <span>
        <svg width="22" height="18">
          <image
            xlink:href="../../../assets/site/logo-display.svg"
            width="22"
            height="18"
          />
        </svg>
      </span>
      <span class="display-text"
        >{{ "raccourcis.affichage" | translate }}
      </span>
    </div>
    <div class="display-arrow">
      <img src="../../../assets/site/arrow-down.svg" />
    </div>
  </button>

  <mat-menu
    mat-button
    aria-expanded="false"
    #display="matMenu"
    aria-label="Accessibilité bouton réduit"
    class="accesibility-display"
    role="menu"
  >
    <div mat-menu-item class="display-item non-clickable">
      <input
        aria-label="Affichage des cartes"
        type="checkbox"
        id="switch"
        (change)="showMap($event)"
        [checked]="isMapEnabled"
      /><label for="switch">Toggle</label>
      <span class="display-content">{{
        "configurationUser.afficherLesCartes" | translate
      }}</span>
    </div>
    <div mat-menu-item class="display-item non-clickable">
      <input
        aria-label="Raffraichissement des horaires"
        type="checkbox"
        id="switchHourly"
        (change)="refreshAuto($event)"
        [checked]="isRefreshEnabled"
      /><label for="switchHourly">Toggle</label>
      <span class="display-content">{{
        "configurationUser.rafraichissementAutoHoraires" | translate
      }}</span>
    </div>
  </mat-menu>
  
 <ctw-member-button
    role="button"
    aria-label="Accéder à mon compte"
    (raisedEvent)="raisedEvent($event)"
    >
  </ctw-member-button>
</div>
