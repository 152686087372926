export const environment = {
  production: true,
  baseWebSite: '',
  language: {
    localeKey: 'locale',
    default: 'fr',
  },
  timeoutWebServiceCMS: 3000,
  homePageNewsNumber: 8,
  paginationNewsNumber: 10,
  baseUrlApiMgt: 'https://api-int.myastuce.fr/content',
  cleApiName: 'Ocp-Apim-Subscription-Key',
  cleApi: '73511e26e64cbbc86f38c245784d22f2',
  buildNumber: '20241204.1',
  baseUrlCityway: 'https://preprod.staticwc.mrn.cityway.fr/Site',
  formulaireContactMaaSCityway: 'https://ca.preprod.microtransit.cityway.fr/MRN/Claim',
  searchCharacterMin: 100,
  searchCharacterMax: 100,
  name: 'int'
};

