import { Component, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { LayoutService } from './layout/layout.service';
import { StorageService } from './services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public title = 'Webfront';
  public langage!: string;

  constructor(
    private translate: TranslateService,
    private storage: StorageService,
    private layout: LayoutService,
    private renderer: Renderer2
  ) {
    const localStorage = this.storage.getData(environment.language.localeKey);
    if (localStorage) {
      this.langage = localStorage;
    } else {
      this.langage = environment.language.default;
      this.storage.setDataString(environment.language.localeKey, this.langage);
    }

    this.layout.setLanguage(this.langage);
    //a valider que c'est la valeur dans l'url
    this.translate.addLangs(['en', 'fr']);

    // A rajouter pour le fonctionnement des webcomponents
    const citywayurl = environment.baseUrlCityway;
    this.storage.setDataString('citywayurl', citywayurl);

    const scriptRunTime = this.renderer.createElement('script');
    scriptRunTime.src = environment.baseUrlCityway + '/v8/js/web-components.js';
    this.renderer.appendChild(document.head, scriptRunTime);
    const scriptWebComponent = this.renderer.createElement('script');
    scriptWebComponent.src = environment.baseUrlCityway + '/v8/js/runtime.js';
    this.renderer.appendChild(document.head, scriptWebComponent);
    this.translate.use(this.langage);

    // Utilisé pour le moment pour pouvoir se connecter en local et pouvoir afficher le TAD et taxi. Attention à ne pas pousser sur le main
    //sessionStorage.setItem('ctw-redirect-uri', 'http://localhost:4200/fr/mon-compte/connect');
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
  }
}
