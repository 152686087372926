import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import menuJson from '../../../assets/configuration/menu.json';
import { LayoutService } from '../layout.service';

@Component({
  selector: 'app-side-nav-list',
  templateUrl: './side-nav-list.component.html',
  styleUrls: ['./side-nav-list.component.scss'],
})
export class SideNavListComponent implements OnInit, OnDestroy {
  @Output() sidenavClose = new EventEmitter();
  public items = menuJson;
  //baseWebSite: string;
  private subscriptionRemover = new Subject<void>();

  visiblePanelPrincipal = true;
  visiblePanelSeDeplacer = false;
  visiblePanelSInformer = false;
  visiblePanelAcheter = false;
  visiblePanelPro = false;

  language!: string;

  isMapEnabled: boolean = true;
  isRefreshEnabled: boolean = true;

  constructor(
    private translate: TranslateService,
    private storage: StorageService,
    private layout: LayoutService
  ) {
    // this.baseWebSite = environment.baseWebSite;
    this.language = this.translate.currentLang;
    this.translate.onLangChange
      .pipe(takeUntil(this.subscriptionRemover))
      .subscribe(
        (langChangeEvent: LangChangeEvent) =>
          (this.language = langChangeEvent.lang)
      );
  }

  ngOnInit(): void {
    // Initialiser les valeurs depuis le localStorage
    const showMaps = this.storage.getData('showMaps');
    const refreshAuto = this.storage.getData('refreshAuto');

    // Récupération des valeurs initiales du LocalStorage
    // Si le localStorage n'a pas de valeur, on considère que le toggle est activé par défaut
    this.isMapEnabled = (showMaps === null ? true : showMaps === '1');
    this.isRefreshEnabled = (refreshAuto === null ? true : refreshAuto === '1');
  }

  public onSidenavClose = () => {
    this.sidenavClose.emit();
    this.toggle();
  };

  public showPanel(panel: string): void {
    this.visiblePanelPrincipal = false;
    if (panel.toUpperCase() === 'SE-DEPLACER') {
      this.visiblePanelSeDeplacer = true;
    }
    if (panel.toUpperCase() === 'S-INFORMER') {
      this.visiblePanelSInformer = true;
    }
    if (panel.toUpperCase() === 'ACHETER') {
      this.visiblePanelAcheter = true;
    }
    if (panel.toUpperCase() === 'PRO') {
      this.visiblePanelPro = true;
    }
  }

  public toggle(): void {
    this.visiblePanelPrincipal = true;
    this.visiblePanelSeDeplacer = false;
    this.visiblePanelSInformer = false;
    this.visiblePanelAcheter = false;
    this.visiblePanelPro = false;
  }

  public useLanguage(language: string): void {
    this.language = language;
    this.layout.switchLocale(this.language);
    this.onSidenavClose();
  }

  showMap(event: any) {
    const isChecked = event.checked; // Get the toggle state
    this.storage.setDataString('showMaps', isChecked ? '1' : '0');
    window.location.reload();
  }
  
  refreshAuto(event: any) {
    const isChecked = event.checked; // Get the toggle state
    this.storage.setDataString('refreshAuto', isChecked ? '1' : '0');
    window.location.reload();
  }

  ngOnDestroy(): void {
    this.subscriptionRemover.next();
    this.subscriptionRemover.complete();
  }
}
