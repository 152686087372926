<mat-nav-list>
  <ul class="nav-list-mobile" *ngIf="visiblePanelPrincipal">
    <li class="item-menu-mobile top-menu-mobile">
      <button mat-button (click)="showPanel('SE-DEPLACER')" class="subMenu">
        <mat-icon class="icon-menu-mobile">directions_walk</mat-icon>
        <span class="text-menu-mobile" translate>menu.seDeplacer</span>
      </button>
    </li>
    <li class="item-menu-mobile">
      <button mat-button class="subMenu" (click)="showPanel('S-INFORMER')">
        <mat-icon class="icon-menu-mobile">article</mat-icon>
        <span class="text-menu-mobile" translate>menu.sInformer</span>
      </button>
    </li>
    <li class="item-menu-mobile">
      <button mat-button class="subMenu" (click)="showPanel('ACHETER')">
        <mat-icon class="icon-menu-mobile">shopping_cart</mat-icon>
        <span class="text-menu-mobile" translate>menu.acheter</span>
      </button>
    </li>
    <li class="item-menu-mobile-accordion">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="icon-menu-mobile">remove_red_eye</mat-icon>
              <span class="text-menu-mobile" translate
                >raccourcis.affichage</span
              >
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-slide-toggle
            (change)="showMap($event)"
            [checked]="isMapEnabled"
          >{{
            "configurationUser.afficherLesCartes" | translate
          }}</mat-slide-toggle>
          <mat-slide-toggle
            (change)="refreshAuto($event)"
            [checked]="isRefreshEnabled"
          >{{
            "configurationUser.rafraichissementAutoHoraires" | translate
          }}</mat-slide-toggle>
        </mat-expansion-panel>
      </mat-accordion>
    </li>
    <li class="item-menu-mobile-accordion">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon class="icon-menu-mobile">translate</mat-icon>
              <span class="text-menu-mobile" translate>raccourcis.langue</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <button mat-menu-item (click)="useLanguage('fr')">
            <img
              class="img-langue"
              src="../../../assets/site/fr.svg"
              alt="fr"
            />
            <span class="txtLanguage" translate>menu.francais</span>
          </button>
          <button mat-menu-item (click)="useLanguage('en')">
            <img
              class="img-langue"
              src="../../../assets/site/en.svg"
              alt="en"
            />
            <span class="txtLanguage" translate>menu.anglais</span>
          </button>
        </mat-expansion-panel>
      </mat-accordion>
    </li>
  </ul>
</mat-nav-list>
<app-menu-panel-mobile
  [labelRetour]="'menu.seDeplacer'"
  [items]="items['SE-DEPLACER']"
  [visible]="visiblePanelSeDeplacer"
  (closePanel)="toggle()"
  (sidenavClose)="onSidenavClose()"
></app-menu-panel-mobile>
<app-menu-panel-mobile
  [labelRetour]="'menu.sInformer'"
  [items]="items['S-INFORMER']"
  [visible]="visiblePanelSInformer"
  (closePanel)="toggle()"
  (sidenavClose)="onSidenavClose()"
></app-menu-panel-mobile>

<app-menu-panel-mobile
  [labelRetour]="'menu.acheter'"
  [items]="items['ACHETER']"
  [visible]="visiblePanelAcheter"
  (closePanel)="toggle()"
  (sidenavClose)="onSidenavClose()"
></app-menu-panel-mobile>
